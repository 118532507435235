import { Units } from '../models/unit';

export const UNITS_TABLE_DATA: Units[] = [
  {
    unitName: 'Length/depth',
    formName: 'lengthDepth',
    si: 'm',
    metric: 'm',
    imperial: 'ft',
    custom: ['m', 'ft'],
  },
  {
    unitName: 'Speed/velocity',
    formName: 'speedVelocity',
    si: 'm/s',
    metric: 'm/s',
    imperial: 'ft/s',
    custom: ['m/s', 'ft/s'],
  },
  {
    unitName: 'Pressure',
    formName: 'pressure',
    si: 'Pa',
    metric: 'kPa',
    imperial: 'psi',
    custom: ['kPa', 'psi'],
  },
  {
    unitName: 'Angular speed/velocity',
    formName: 'angularSpeedvelocity',
    si: 'rpm',
    metric: 'rpm',
    imperial: 'rpm',
    custom: ['rpm'],
  },
  {
    unitName: 'Flow',
    formName: 'flow',
    si: 'm3/s',
    metric: 'L/min',
    imperial: 'gal/min',
    custom: ['L/min', 'gal/min'],
  },
  {
    unitName: 'Torque',
    formName: 'torque',
    si: 'N',
    metric: 'Mgf',
    imperial: 'klbf',
    custom: ['Mgf', 'klbf'],
  },
  {
    unitName: 'Hookload',
    formName: 'hookland',
    si: 'N.m',
    metric: 'kN.m',
    imperial: 'klbf.ft',
    custom: ['kN.m', 'klbf.ft'],
  },
];

export const UNIT_DISPLAYED_COLUMNS = ['unitName', 'metric', 'imperial', 'custom'];
export const METRIC = 'metric';
export const IMPERIAL = 'imperial';
export const CUSTOM = 'custom';
export const UNIT_SAVE_SUCCESS = 'unit setting has been applied successfully. ';
export const UNIT_SAVE_ERROR = 'Error occured while saving unit';
export const UNITCONSTANT = {
  METER: 'm',
  MERTERPERSEC: 'm/s',
  PASCAL: 'Pa',
  RPM: 'rpm',
  METERCUBEPERSEC: 'm3/s',
  NEWTON: 'N',
  NEWTONMETER: 'N.m',
  FEET: 'ft',
  FEETPERSEC: 'ft/s',
  KILOPASCAL: 'kPa',
  PSI: 'psi',
  LITREPERMIN: 'L/min',
  GALOONSPERMIN: 'gal/min',
  MILLIGRAVEFORCE: 'Mgf',
  KIPFORCE: 'klbf',
  KILONEWTONMETER: 'kN.m',
  KIPFORCEPERFEET: 'klbf.ft',
};

export const UNITVALUECONSTANT = {
  METERTOFEET: 3.280839895013123,
  UNITTOKILO: 0.001,
  PASCALTOPSI: 0.0001450377377302092,
  M3PERSECTOLPERMIN: 6000.0,
  M3PERSECTOGPERMIN: 15850.323141488905,
  NEWTOMGF: 0.00010197162129779283,
  NEWTOKLPF: 0.0002248089430997105,
  NMTOKLPFFT: 0.0007375621492772653,
};

export const USER_ROUTERLINKS = [
  {
    label: 'Units',
    routerLink: 'unit',
    active: true,
  },
  {
    label: 'Notification',
    routerLink: 'notification',
    active: false,
  },
];
