import { RadioButtonValue } from '@slb-dls/angular-material/radio-button-group';
import { EventStatusItems } from '../models/global-view';

export class GlobalViewConstant {
  public static globalWorkFlowAPIError = 'Error while receiving workflow details.';
  public static GLOBALCOUNTRYERROR = 'Error while receiving country details.';
  public static GLOBALRISKERROR = 'Error while receiving risk details.';
  public static GLOBALSITEERROR = 'Error while receiving site details.';
  public static workFlowItems: EventStatusItems[] = [
    { name: 'All', value: 'All', checked: true },
    { name: 'Red zone', value: 'Red zone', checked: true },
    { name: 'PPE zone', value: 'PPE zone', checked: true },
    { name: 'Catwalk', value: 'Catwalk', checked: true },
  ];
  public static EventStatusItems: EventStatusItems[] = [
    { name: 'All', value: 'ALL', checked: true },
    { name: 'Valid', value: 'VALID', checked: true },
    { name: 'Invalid', value: 'INVALID', checked: true },
    { name: 'Not Reviewed', value: 'NOTREVIEWED', checked: true },
    { name: 'Excluded', value: 'EXCLUDE', checked: true },
  ];
  public static customOption: RadioButtonValue[] = [
    {
      value: 'custom',
      name: 'Custom',
      isDefault: false,
    },
  ];
  public static dateRange: RadioButtonValue[] = [
    {
      value: 'day',
      name: 'Last 24 hours',
      isDefault: false,
    },
    {
      value: 'twoday',
      name: 'Last 2 days',
      isDefault: false,
    },
    {
      value: 'week',
      name: 'Last 7 days',
      isDefault: false,
    },
    {
      value: 'month',
      name: 'Last 30 days',
      isDefault: false,
    },
    {
      value: 'year',
      name: 'Last year',
      isDefault: false,
    },
  ];
  public static eventFlagTypes: RadioButtonValue[] = [
    {
      value: 'not reviewed',
      name: 'Not reviewed',
      isDefault: true,
    },
    {
      value: 'valid',
      name: 'Valid',
      isDefault: false,
    },
    {
      value: 'invalid',
      name: 'Invalid',
      isDefault: false,
    },
    {
      value: 'exclude',
      name: 'Exclude',
      isDefault: false,
    },
  ];

  public static eventFlagTypesNonAdmin: RadioButtonValue[] = [
    {
      value: 'not reviewed',
      name: 'Not reviewed',
      isDefault: true,
    },
    {
      value: 'valid',
      name: 'Valid',
      isDefault: false,
    },
    {
      value: 'invalid',
      name: 'Invalid',
      isDefault: false,
    },
  ];
}

export const GLOBAL_TABLE_HEADING = [
  'select',
  'timestamp',
  'gatewayName',
  'equipmentName',
  'peopleInsideRedZone',
  'BPOS',
  'BVEL',
  'RPM',
  'SPPA',
  'category',
  'lastComment',
  'action',
];
export const GLOBAL_CSV_DOWNLOAD_HEADING = [
  'timestamp',
  'eventId',
  'type',
  'peopleInsideRedZone',
  'imageFilename',
  'videoFilename',
  'reason',
  'category',
  'comment',
];

export const DAYMODE = {
  HOUR: 'hour',
  DAY: 'day',
  TWODAY: 'twoday',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
  CUSTOM: 'custom',
};

export const CHARTTIMELINE = {
  HOUR: 'Last 1 hour',
  DAY: 'Last 24 hours',
  TWODAY: 'Last 2 days',
  WEEK: 'Last 7 days',
  MONTH: 'Last 30 days',
  YEAR: 'Last year',
};

export const WORKFLOWEVENT = {
  REDZONELABEL: 'Red zone',
  PPELABEL: 'PPE zone',
  CATWALKLABEL: 'Catwalk',
  REDZONE: 'redzone',
  PPE: 'ppe',
  CATWALK: 'catwalk',
  ALLLABEL: 'All',
  ALL: 'all',
};

export const HOURDURATION = {
  SECONDS_IN_HOUR: 3600 * 1000,
  HOUR: 1,
  DAY: 24,
  WEEK: 168,
  MONTH: 720,
  TWODAY: 48,
  YEAR: 8760,
};

export const FILTEREVENTS = {
  RISK: 'risk',
  WORKFLOW: 'workflow',
  SITE: 'site',
  EVENT: 'event',
  COUNTRY: 'country',
};
