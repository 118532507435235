<div class="notification-header">
  <div class="header">
    <mat-button-toggle-group
      disableRipple="true"
      (change)="switchNotification($event)"
      [value]="notification"
      id="vi_notification_button_group"
      class="days-button-group">
      <mat-button-toggle value="all">All </mat-button-toggle>
      <mat-button-toggle value="system"> System Notification </mat-button-toggle>
      <mat-button-toggle value="trend"> Trends Notification </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div [formGroup]="formGroup" class="date-details">
    <mat-form-field *ngIf="siteList">
      <mat-label>Site</mat-label>
      <mat-select formControlName="assetId" (selectionChange)="filterChange()" class="min-max-field" hideSingleSelectionIndicator="true">
        <mat-option *ngFor="let site of siteList" [value]="site.id">
          {{ site.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field slbFormField>
      <mat-label>From</mat-label>
      <input
        formControlName="startDate"
        placeholder="Select date..."
        (formattedValueChange)="formattedCalendar = $event"
        id="vi_notification_startDate"
        (valueChange)="calendarEvent = $event"
        [slbDateTimePicker]="startDateTimePicker"
        (ngModelChange)="filterChange()"
        [max]="todayDate"
        matInput />
      <slb-date-time-picker-toggle [for]="startDateTimePicker" matSuffix> </slb-date-time-picker-toggle>
      <slb-date-time-picker-popup #startDateTimePicker></slb-date-time-picker-popup>
    </mat-form-field>
    <mat-form-field slbFormField>
      <mat-label>To</mat-label>
      <input
        formControlName="endDate"
        placeholder="Select date..."
        (formattedValueChange)="formattedCalendar = $event"
        id="vi_notification_endDate"
        (valueChange)="calendarEvent = $event"
        [slbDateTimePicker]="endDateTimePicker"
        (ngModelChange)="filterChange()"
        [max]="todayDate"
        matInput />
      <slb-date-time-picker-toggle [for]="endDateTimePicker" matSuffix> </slb-date-time-picker-toggle>
      <slb-date-time-picker-popup #endDateTimePicker></slb-date-time-picker-popup>
    </mat-form-field>
  </div>
  <button slb-button class="apply-btn" [disabled]="disableApply" (click)="apply()">Apply</button>
</div>
<div class="error-holder" *ngIf="endDateError || currentDateError">
  <mat-error *ngIf="endDateError">End date should be greater than the start date.</mat-error>
  <mat-error *ngIf="currentDateError">Start date and end date should be less than the current date.</mat-error>
</div>

<div class="notification-content" *ngIf="!isLoading; else loader">
  <div *ngIf="notificationList?.length === 0 || (isTrend && !isTrendAvailable) || (isSystem && !isSystemAvailable)">
    <div class="notification-list-data">
      <div class="icon-data" *ngIf="isSystem && !isSystemAvailable; else trend">
        <span class="icon-wrap system-wrap">
          <mat-icon svgIcon="system-configuration" class="icon-details">Download</mat-icon>
        </span>
      </div>
      <ng-template #trend>
        <div class="icon-data">
          <span class="icon-wrap trend-wrap">
            <mat-icon svgIcon="scale" class="icon-details">Download</mat-icon>
          </span>
        </div>
      </ng-template>
      <div class="system-details">
        <div>
          <h2 class="heading">No Notification</h2>
          <p>No notification has been triggered since 24 hours  </p>
          <p *ngIf="isSystem && !isSystemAvailable; else trendKnowMore">
            <a href="/camera" class="know-more">Know more</a>
          </p>
          <ng-template #trendKnowMore>
            <p>
              <a href="/analytics" class="know-more">Know more</a>
            </p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div *ngFor="let notification of notificationList; let index = index" [ngClass]="{ bgClass: index % 2 }" class="notfication-list">
    <div *ngIf="notification?.notification?.userNotificationTrend && isTrend" class="notification-list-data">
      <div class="icon-data">
        <span class="icon-wrap trend-wrap"><mat-icon svgIcon="scale" class="icon-details">Download</mat-icon></span>
      </div>
      <div class="system-details">
        <div *ngIf="notification?.notification?.userNotificationTrend?.noNotification; else notified">
          <h2 class="heading">No Notification</h2>
          <p>No notification has been triggered since 24 hours  </p>
          <p>
            <a href="/analytics" class="know-more">Know more</a>
          </p>
        </div>
        <ng-template #notified>
          <div>
            <div class="head-wrap">
              <h2 class="heading">{{ notification?.notification?.cameraName }}</h2>
              <p>{{ notification.createdAt | date : 'MM/dd/yyyy HH:mm:ss a' : currentZone }}</p>
            </div>
            <p class="mt-0">
              Event trends is
              <span *ngIf="notification?.notification?.userNotificationTrend?.min"
                >less than {{ notification?.notification?.userNotificationTrend?.min }}</span
              ><span
                *ngIf="notification?.notification?.userNotificationTrend?.min && notification?.notification?.userNotificationTrend?.max">
                or</span
              >
              <span *ngIf="notification?.notification?.userNotificationTrend?.max"
                >greater than {{ notification?.notification?.userNotificationTrend?.max }}</span
              >, please review
            </p>
            <p>
              <a href="/analytics" class="know-more">Know more</a>
            </p>
          </div>
        </ng-template>
      </div>
    </div>
    <div *ngIf="notification?.notification?.userNotificationCamera && isSystem" class="notification-list-data">
      <div class="icon-data">
        <span class="icon-wrap system-wrap"><mat-icon svgIcon="system-configuration" class="icon-details">Download</mat-icon></span>
      </div>
      <div class="system-details">
        <div class="head-wrap">
          <h2 class="heading">{{ notification?.notification?.cameraName }} ({{ notification?.notification?.siteName }})</h2>
          <p>{{ notification.createdAt | date : 'MM/dd/yyyy HH:mm:ss a' : currentZone }}</p>
        </div>
        <p class="mt-0">
          Gateway {{ notification?.notification?.siteName }} was inactive ,So the camera
          {{ notification?.notification?.userNotificationCamera?.cameraName }} was inactive. Please check the connection or trigger a
          maintenance at the site.
        </p>
        <app-system-notification-table
          *ngIf="notification?.notification?.userNotificationCamera?.offlineTime?.length"
          [currentZone]="currentZone"
          [data]="notification?.notification?.userNotificationCamera"></app-system-notification-table>
      </div>
    </div>
    <div *ngIf="notification?.notification?.userNotificationWits && isSystem" class="notification-list-data">
      <div class="icon-data">
        <span class="icon-wrap system-wrap"><mat-icon svgIcon="system-configuration" class="icon-details">Download</mat-icon></span>
      </div>
      <div class="system-details">
        <div class="head-wrap">
          <h2 class="heading">{{ notification?.notification?.cameraName }} ({{ notification?.notification?.siteName }})</h2>
          <p>{{ notification.createdAt | date : 'MM/dd/yyyy HH:mm:ss a' : currentZone }}</p>
        </div>
        <p *ngIf="notification?.notification?.userNotificationWits?.witsDelay; else noDelay" class="mt-0">
          WITS communication is functional but WITS time delay is >
          {{ notification?.notification?.userNotificationWits?.witsDefaultValue }}s (currently at
          {{ notification?.notification?.userNotificationWits?.witsDelay }} secs).
        </p>
        <ng-template #noDelay>
          <p class="mt-0">WITS communication is disrupted and WITS time delay is 0.</p>
        </ng-template>
        <p>
          <a href="/camera" class="know-more">Know more</a>
        </p>
      </div>
    </div>
  </div>
</div>
<ng-template #loader>
  <div class="spinner">
    <app-spinner></app-spinner>
  </div>
</ng-template>
<slb-toast></slb-toast>
