import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { Category, EventDetails } from '../../shared/models/eventDetails';
import { CameraProfileService } from '../../shared/services/camera-profile.service';
import { Subject, catchError, of, takeUntil, tap } from 'rxjs';
import {
  EVENTFLAGERROR,
  EVENTREVIEWCOMMENTPOSTERROR,
  EVENTVIEWERROR,
  MULTIEVENTDATA,
  NONE,
  NOT_REVIEWED,
  NOT_REVIEWED_CAPS,
} from '../../shared/constants/camera-profile-constant';
import { EventReviewConstants } from '../../../app/shared/mocks/event-review-mockup';
import { LoginService } from '@agora/agora-ui-library';
import { EventDataView, EventView, FlagEventCount } from '../../../app/shared/models/camera-profile';
import { RadioButtonValue } from '@slb-dls/angular-material/radio-button-group';
import { GlobalViewConstant } from 'src/app/shared/constants/global-view-constant';

@Component({
  selector: 'app-event-review',
  templateUrl: './event-review.component.html',
  styleUrls: ['./event-review.component.scss'],
})
export class EventReviewComponent implements OnInit, OnDestroy {
  public eventForm: FormGroup = this.fb.group({
    eventId: [''],
    flag: ['', Validators.required],
    category: ['', Validators.required],
    comment: ['', Validators.required],
  });

  public categories: Category[];
  public controllerId: string;
  public gatewayId: string;
  public eventData: EventDetails;
  public userName: string;
  public userEmail: string;
  public comment = '';
  public isPostDisabled = true;
  public viewCount = 0;
  public getFlagCount: FlagEventCount;
  public acsUserId = 0;
  public flag?: string;
  public selectedFlagButton?: string | null;
  public isExclude = true;
  public isLoading = true;
  public validCount = 0;
  public invalidCount = 0;
  public excludeCount = 0;
  public currentFlag?: string;
  public flagTypes: RadioButtonValue[];
  private destroyed = new Subject();

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    public dialogRef: MatDialogRef<EventReviewComponent>,
    private cameraProfileService: CameraProfileService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) private eventDetails: EventDetails[]
  ) {
    if (this.eventDetails.length === 1) {
      this.eventData = structuredClone(this.eventDetails[0]);
    } else {
      this.eventData = structuredClone(MULTIEVENTDATA);
    }
  }

  ngOnInit(): void {
    this.userName = this.loginService.getUserInfo()?.name ?? '';
    this.userEmail = this.loginService.getUserInfo()?.email ?? '';
    this.selectedFlagButton = null;
    this.currentFlag = !this.eventData.category || this.eventData.category === NONE ? NOT_REVIEWED : this.eventData.category.toLowerCase();
    this.flag = this.currentFlag;
    this.isExclude = sessionStorage.getItem('isAdmin') === 'true' ? true : false;
    this.flagTypes = this.isExclude ? GlobalViewConstant.eventFlagTypes : GlobalViewConstant.eventFlagTypesNonAdmin;
    this.getTotalViewCount();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  public getInitials(fullName: string): string {
    if (!fullName) {
      return '';
    }
    const nameParts = fullName.trim().split(' ');
    if (nameParts.length < 2) {
      return fullName.charAt(0).toUpperCase();
    }
    const firstNameInitial = nameParts[0].charAt(0).toUpperCase();
    const lastNameInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1].charAt(0).toUpperCase() : '';

    return `${firstNameInitial}${lastNameInitial}`;
  }

  public checkComment(): void {
    this.isPostDisabled = this.flag !== this.currentFlag || this.comment.trim().length !== 0 ? false : true;
  }

  public postComment(): void {
    if (!this.isPostDisabled) {
      if (this.comment.length !== 0) {
        const commentJson = {
          eventId: this.eventData.eventId,
          comment: this.comment,
          username: this.userName,
          userEmail: this.userEmail,
        };
        this.cameraProfileService
          .saveEventComments(commentJson)
          .pipe(
            tap(() => {
              this.cameraProfileService.getEventData(false);
              this.messageService.add({ severity: SlbSeverity.Success, summary: EventReviewConstants.successMessage });
              this.dialogRef.close();
            }),
            catchError(() => {
              this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTREVIEWCOMMENTPOSTERROR });

              return of<string>('');
            }),
            takeUntil(this.destroyed)
          )
          .subscribe();
      }
      console.log(this.currentFlag, this.flag);
      if (this.currentFlag !== this.flag) {
        let flagValue = !this.currentFlag || this.currentFlag === NOT_REVIEWED ? NONE : this.currentFlag;
        this.cameraProfileService
          .setFlagData(this.eventData.eventId, this.userName, this.userEmail, flagValue)
          .pipe(
            tap((resData: EventDataView) => {
              this.cameraProfileService.getEventData(false);
              this.getFlagCount = resData.eventDetails;
              flagValue =
                !this.currentFlag || flagValue === NONE
                  ? NOT_REVIEWED_CAPS
                  : flagValue[0].toUpperCase() + flagValue.slice(1).toLocaleLowerCase();
              this.messageService.add({
                severity: SlbSeverity.Success,
                summary: `${flagValue} flag added for ${this.eventData.eventId} by ${this.userName}`,
              });
              this.flag = this.currentFlag;

              this.dialogRef.close();
            }),
            catchError(() => {
              this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTFLAGERROR });

              return of<string>('');
            }),
            takeUntil(this.destroyed)
          )
          .subscribe();
      }
    }
    this.comment = '';
    this.isPostDisabled = true;
  }

  public getTotalViewCount(): void {
    this.cameraProfileService
      .getViewCounts(this.eventData.eventId)
      .pipe(
        tap((resData: EventView) => {
          this.viewCount = !resData ? 0 : resData.totalCount;
          const checkUser = resData?.views.find((user: { userEmail: string }) => user.userEmail === this.userEmail);
          if (!checkUser || !this.viewCount) {
            this.addViewCount();
          }
          this.isLoading = false;
        }),
        catchError(() => {
          this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTVIEWERROR });

          return of<string>('');
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public addViewCount(): void {
    this.cameraProfileService
      .createViewCounts(this.eventData.eventId, this.userName, this.userEmail, this.acsUserId)
      .pipe(
        catchError(() => {
          this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTVIEWERROR });

          return of<string>('');
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public setFlagData(): void {
    this.isPostDisabled = this.flag !== this.currentFlag || this.comment.length !== 0 ? false : true;
  }
}
