import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavItem } from '@slb-dls/angular-material/shared';
import { USER_ROUTERLINKS } from '../../../app/shared/constants/unit-constant';

@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss'],
})
export class UserSettingComponent implements OnInit {
  public routerLinks = USER_ROUTERLINKS;
  public secondaryNavigationItems: NavItem[];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.secondaryNavigationItems = JSON.parse(JSON.stringify(this.routerLinks));
    const url = document.location.href;
    const checkUrl = url.split('/')?.[3];
    this.secondaryNavigationItems.forEach((navItem: NavItem) => {
      navItem.active = checkUrl === navItem.routerLink ? true : false;
    });
    this.cd.detectChanges();
  }
}
