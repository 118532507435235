import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NotificationService } from '../shared/services/notification.service';
import { GlobalViewService } from '../shared/services/global-view.service';
import { catchError, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { WorkFlow } from '../shared/models/global-view';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { GlobalViewConstant } from '../shared/constants/global-view-constant';
import { NotificationDetails, NotificationFilter } from '../shared/models/notification';
import { NOTIFICATION } from '../shared/constants/notification-constant';
import { TimeZoneService } from '../shared/services/time-zone.service';
import { CameraProfileService } from '../shared/services/camera-profile.service';

@Component({
  selector: 'app-notification-page',
  templateUrl: './notification-page.component.html',
  styleUrls: ['./notification-page.component.scss'],
})
export class NotificationPageComponent implements OnInit, OnDestroy {
  public notification: string;
  public todayDate: Date;
  public formattedCalendar: string;
  public calendarEvent: Event;
  public startDate: Date;
  public endDate: Date;
  public siteList: WorkFlow[];
  public formGroup: FormGroup;
  public notificationList: NotificationDetails[];
  public currentZone: string;
  public isSystem: boolean;
  public isTrend: boolean;
  public isLoading: boolean;
  public disableApply = true;
  public endDateError: boolean;
  public isRequired: boolean;
  public currentDateError: boolean;
  public isTrendAvailable: boolean;
  public isSystemAvailable: boolean;

  private destroyed = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private cameraProfileService: CameraProfileService,
    private messageService: MessageService,
    private globalService: GlobalViewService,
    private timeZoneService: TimeZoneService,
    private cd: ChangeDetectorRef
  ) {
    this.currentZone = this.timeZoneService.getTimeZone();
  }

  ngOnInit(): void {
    this.cameraProfileService.resetAnalyticsSelection();
    this.notification = NOTIFICATION.ALL_EVENT;
    this.isSystem = true;
    this.isTrend = true;
    this.isLoading = false;
    this.isSystemAvailable = true;
    this.isTrendAvailable = true;
    this.getData();

    const startDate = null;

    this.formGroup = this.formBuilder.group({
      startDate: [startDate, [this.startDateValidator.bind(this)]],
      endDate: [null, [this.endDateValidator.bind(this)]],
      assetId: [],
    });
    this.todayDate = new Date();
  }

  startDateValidator(group: FormControl): { [key: string]: any } | null {
    if (this.formGroup) {
      const start = group as FormControl;
      const endForm = this.formGroup.get('endDate') as FormControl;
      const end = this.formGroup.value.endDate;

      if (start && end && start.value > end) {
        return { dateRangeInvalid: true };
      } else {
        if (endForm.hasError('dateRangeInvalid')) {
          this.formGroup.get('endDate')?.setErrors(null);
          this.formGroup.updateValueAndValidity();
        }
      }
    }

    return null;
  }

  endDateValidator(group: FormControl): { [key: string]: any } | null {
    if (this.formGroup) {
      const startForm = this.formGroup.get('startDate') as FormControl;
      const start = startForm.value;
      const end = group as FormControl;

      if (start && end && start > end.value) {
        return { dateRangeInvalid: true };
      } else {
        if (startForm.hasError('dateRangeInvalid')) {
          this.formGroup.get('startDate')?.setErrors(null);
          this.formGroup.updateValueAndValidity();
        }
      }
    }

    return null;
  }

  public apply(): void {
    this.disableApply = true;
    this.getNotificationData().subscribe();
  }

  public switchNotification(event: { value: string }): void {
    if (event?.value === NOTIFICATION.SYSTEM) {
      this.isSystemAvailable =
        this.notificationList?.filter(data => data?.notification?.userNotificationWits || data?.notification?.userNotificationCamera)
          ?.length > 0;
      this.isSystem = true;
      this.isTrend = false;
      this.isTrendAvailable = false;
    } else if (event?.value === NOTIFICATION.TREND) {
      this.isTrendAvailable = this.notificationList?.filter(data => data?.notification?.userNotificationTrend)?.length > 0;
      this.isSystem = false;
      this.isTrend = true;
      this.isSystemAvailable = true;
    } else {
      this.isSystem = true;
      this.isTrend = true;
      this.isSystemAvailable = true;
      this.isTrendAvailable = true;
    }
  }

  public filterChange(): void {
    this.endDateError = false;
    this.isRequired = false;
    this.currentDateError = false;
    this.disableApply = true;
    if (
      (this.formGroup.value.startDate &&
        this.formGroup.value.endDate &&
        this.formGroup.value.startDate?.getTime() > new Date().getTime()) ||
      this.formGroup.value.endDate?.getTime() > new Date().getTime()
    ) {
      this.currentDateError = true;
    } else if (
      this.formGroup.value.startDate &&
      this.formGroup.value.endDate &&
      this.formGroup.value.startDate?.getTime() > this.formGroup.value.endDate?.getTime()
    ) {
      this.endDateError = true;
    } else {
      if (this.formGroup.value.startDate !== null && this.formGroup.value.endDate !== null) {
        this.disableApply = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  private getData(): void {
    this.isLoading = true;
    this.globalService
      .getSiteList([NOTIFICATION.ALL])
      .pipe(
        catchError(() => {
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: GlobalViewConstant.globalWorkFlowAPIError,
            closable: true,
            sticky: true,
          });
          this.isLoading = false;

          return of<WorkFlow[]>({} as WorkFlow[]);
        }),
        tap((siteList: WorkFlow[]) => {
          this.siteList = siteList;
          this.siteList = [
            {
              id: NOTIFICATION.ALL,
              name: NOTIFICATION.ALL,
            },
          ].concat(this.siteList);
          this.formGroup?.patchValue({
            assetId: this.siteList[0].id,
          });
          this.cd.detectChanges();
        }),
        switchMap(() => this.getNotificationData()),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private getNotificationData(): Observable<NotificationDetails[]> {
    const payload = this.formGroup.value as NotificationFilter;
    payload.assetId = payload.assetId === NOTIFICATION.ALL ? null : payload.assetId;
    payload.startDate = payload.startDate === null ? null : new Date(payload.startDate).toISOString();
    payload.endDate = payload.endDate === null ? null : new Date(payload.endDate).toISOString();
    this.isLoading = true;

    return this.notificationService.getNotificationData(payload as NotificationFilter).pipe(
      tap((notificationData: NotificationDetails[]) => {
        this.notificationList = notificationData;
        this.isLoading = false;
      }),
      catchError(() => {
        this.messageService.add({
          severity: SlbSeverity.Error,
          summary: NOTIFICATION.GETNOTIFICATIONDATAERROR,
          closable: true,
          sticky: true,
        });
        this.isLoading = false;

        return of<NotificationDetails[]>({} as NotificationDetails[]);
      }),
      takeUntil(this.destroyed)
    );
  }
}
